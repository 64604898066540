function loadSliders() {
	$('.testimonials .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 1,
		prevArrow: "<button class='testimonials__button testimonials__button--prev' aria-label='Previous' type='button'><img src='/theme/arrow.png' alt=''/></button>",
		nextArrow: "<button class='testimonials__button testimonials__button--next' aria-label='Next' type='button'><img src='/theme/arrow.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});


$('.featured-categories__title').attr("data-text", function () {
	return $(this).text();
});
$('.toplinks__link--alt').attr("data-text", function () {
	return $(this).text();
});
$('.castleCheckBook').attr("data-text", function () {
	return $(this).text();
});

$(document).ready(function () {

	$('.textContent a').addClass("has-tooltip");

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		$("#TopLinksInner .dropdown-menu a").addClass("animated");
		window.scrollBy(0, 1);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});


	if ($('#HomepageMarker').length > 0) {

		// this removes the '/' between testimonial author & date if there are any testimonials
		// its difficult to style otherwise & is rarely wanted.
		var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
		if (testimonialAuthors.length > 0) {
			$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
				$(this)[0].nextSibling.nodeValue = '';
			});
		}

		$('.header__logo').addClass("fadeInUp2");
	} else {
		$('.header__logo').css('visibility', 'visible');
	}

	function fixHeights() {
		$('.RotatorTestimonialContent').sameHeight();
		if (window.matchMedia('(min-width:768px)').matches) {

		}
	}
	setTimeout(fixHeights, 300);


	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});
});

